<template>
  <v-dialog v-model="isShown" max-width="600">
    <v-card data-cy="activateAutoDeletionDialog">
      <v-card-title>
        {{ $t('business.auto_deletion.disabled.dialog.title') }}
      </v-card-title>
      <v-card-text>
        <i18n-t v-if="isLegacy" keypath="business.auto_deletion.legacy.dialog.activation.subtitle.text" tag="p">
          <template #highlight>
            <strong>{{ $t('business.auto_deletion.legacy.dialog.activation.subtitle.highlight') }}</strong>
          </template>
        </i18n-t>
        <i18n-t v-else keypath="business.auto_deletion.dialog.activation.subtitle.text" tag="p">
          <template #highlight>
            <strong>{{ $t('business.auto_deletion.dialog.activation.subtitle.highlight') }}</strong>
          </template>
        </i18n-t>
      </v-card-text>
      <v-divider class="mt-4" />
      <v-card-text class="pt-4">
        <skr-checkbox
          v-model="isConsentGiven"
          :label="$t('business.auto_deletion.disabled.dialog.checkbox_label')"
          data-cy="confirm_checkbox"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn size="x-large" color="info" variant="outlined" @click="isShown = false">
          {{ $t('business.auto_deletion.disabled.dialog.no_thanks') }}
        </v-btn>
        <v-spacer />
        <v-btn
          data-cy="activateAutoDeletionButton"
          color="info"
          size="x-large"
          variant="elevated"
          :disabled="!isConsentGiven"
          @click="onActivate"
        >
          {{ $t('business.auto_deletion.disabled.dialog.activate') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import SkrCheckbox from '@/components/SkrCheckbox.vue'

export default defineComponent({
  components: {
    SkrCheckbox,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    isLegacy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isConsentGiven: false,
    }
  },
  computed: {
    isShown: {
      get(): boolean {
        return this.modelValue
      },
      set(newValue: boolean): void {
        this.$emit('update:modelValue', newValue)
      },
    },
  },
  methods: {
    onActivate() {
      if (this.isConsentGiven) {
        this.$emit('activate')
        this.isShown = false
      }
    },
  },
})
</script>
